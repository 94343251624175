const useStatusColor = ({ statusId }: { statusId?: number }) => {
	const STATUS_COLOR = [
		{
			id: 1,
			color: "#B7ACAF",
			colorSecondary: "rgba(102, 102, 102, 0)",
			widgetColor: "#504A56",
			starsColor: "#D3C3BF",
			dividerColor: "#B6ABAE",
			backgroundGradient: "linear-gradient(180deg, #383946 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(90deg, rgba(81, 76, 77, 0.3) 0%, rgba(238, 238, 238, 0.3) 49.32%, rgba(81, 76, 77, 0.3) 100%)",
			rewardsTitleColor: "#B7ACAF",
			levelUpTitle: "#B5AAAE"
		},
		{
			id: 2,
			color: "#BF713B",
			colorSecondary: "rgba(78, 62, 51, 0)",
			widgetColor: "#704222",
			starsColor: "#DE8140",
			dividerColor: "#bd703b",
			backgroundGradient: "linear-gradient(180deg, #482A15 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(191, 113, 59, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(191, 113, 59, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(91.01deg, #BE713B 5.27%, #EDE4DF 90.18%)",
			levelUpTitle: "#DF935B"
		},
		{
			id: 3,
			color: "#A5CAFF",
			colorSecondary: "rgba(102, 102, 102, 0)",
			widgetColor: "#4C5E78",
			starsColor: "#A5CAFF",
			dividerColor: "#A5CAFF",
			backgroundGradient: "linear-gradient(180deg, #38435A -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(90deg, rgba(165, 202, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 49.32%, rgba(165, 202, 255, 0.3) 100%)",
			rewardsTitleColor: "linear-gradient(92.05deg, #A6CAFF 4.45%, #B0D1FF 56.84%, #E4F4FF 91.03%)",
			levelUpTitle: "#B0D1FF"
		},
		{
			id: 4,
			color: "#F7A806",
			colorSecondary: "rgba(77, 68, 51, 0)",
			widgetColor: "#644F23",
			starsColor: "#F7A806",
			dividerColor: "#F7A806",
			backgroundGradient: "linear-gradient(180deg, #3C351E -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(245, 167, 7, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(245, 167, 7, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(92.09deg, #F5A707 2.45%, #EDE4DF 87.35%)",
			levelUpTitle: "#F4CF07"
		},
		{
			id: 5,
			color: "#C4B4A9",
			colorSecondary: "rgba(77, 68, 51, 0)",
			widgetColor: "#69625D",
			starsColor: "#C4B4A9",
			dividerColor: "#C4B4A9",
			backgroundGradient: "linear-gradient(180deg, #34343D -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(170, 170, 170, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(170, 170, 170, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(90.77deg, #CFCFCF 11.9%, #EDE4DF 71.17%)",
			levelUpTitle: "#C3B3A8"
		},
		{
			id: 6,
			color: "#EEE1C0",
			colorSecondary: "rgba(79, 69, 48, 0)",
			widgetColor: "#6C6656",
			starsColor: "#EEE1C0",
			dividerColor: "#C4B4A9",
			backgroundGradient: "linear-gradient(180deg, #423A43 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(238, 225, 192, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(238, 225, 192, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(90deg, #EEE1C0 29.9%, #EDE4DF 71.45%)",
			levelUpTitle: "#C3B3A8"
		},
		{
			id: 7,
			color: "#9F8FF8",
			colorSecondary: "rgba(51, 51, 77, 0)",
			widgetColor: "#443D68",
			starsColor: "#9F8FF8",
			dividerColor: "#9F8FF8",
			backgroundGradient: "linear-gradient(180deg, #2A2947 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(159, 143, 248, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(159, 143, 248, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(90.77deg, #CFCFCF 11.9%, #EDE4DF 71.17%)",
			levelUpTitle: "#9F8FF8"
		},
		{
			id: 8,
			color: "#06952B",
			colorSecondary: "rgba(51, 77, 51, 0)",
			widgetColor: "#226E35",
			starsColor: "#05A52E",
			dividerColor: "#06952B",
			backgroundGradient: "linear-gradient(180deg, #1B3332 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(6, 149, 43, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(6, 149, 43, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(90.77deg, #CFCFCF 11.9%, #EDE4DF 71.17%)",
			levelUpTitle: "#00CD36"
		},
		{
			id: 9,
			color: "#2090FF",
			colorSecondary: "rgba(51, 55, 77, 0)",
			widgetColor: "#125DA8",
			starsColor: "#3A9DFF",
			dividerColor: "#2090FF",
			backgroundGradient: "linear-gradient(180deg, #1E314B -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(32, 144, 255, 0.3) -1.85%, rgba(43, 41, 48, 0.3) 50.72%, rgba(32, 144, 255, 0.3) 104.73%)",
			rewardsTitleColor: "linear-gradient(90.77deg, #CFCFCF 11.9%, #EDE4DF 71.17%)",
			levelUpTitle: "#208FFE"
		},
		{
			id: 10,
			color: "#4FD0D1",
			colorSecondary: "#FFE998 27%, #F2509F 33%, rgba(51, 55, 77, 0) ",
			widgetColor: "#82369C",
			starsColor: "#0AFEE8",
			dividerColor: "#F35F9E",
			lightColor: "#D649FF",
			backgroundGradient: "linear-gradient(180deg, #332A47 -0.46%, #1A1F2C 68.9%)",
			backgroundTitle:
				"linear-gradient(98.25deg, rgba(13, 162, 159, 0.3) -1.85%, rgba(209, 77, 146, 0.3) 49.31%, rgba(67, 86, 184, 0.3) 91.94%)",
			rewardsTitleColor: "linear-gradient(90deg, #51D0D2 14.22%, #E3ECA2 60.41%, #FF7BC1 81.9%)",
			levelUpTitle: "#E961FF"
		}
	];

	const colorSchema = computed(() => STATUS_COLOR[(statusId || 1) - 1]).value;

	return {
		STATUS_COLOR,
		colorSchema
	};
};

export default useStatusColor;
